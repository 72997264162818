import { HashLink as Linko } from 'react-router-hash-link';
import { Link } from "react-router-dom";
import { Box, Flex, Heading, Image, List, ListItem, OrderedList,Text,UnorderedList } from '@chakra-ui/react';

function Buy() {
    return (
      <Flex
        w="full"
        direction="column"
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        pb="50px">
        <Box
          w='full'
          pt="50px"
          pb="50px"
          px="10vw"
          // borderBottom={"1px dashed"}
          borderBottomColor={"brand.darkBrown"}>
          <Heading fontSize={"90px"} lineHeight={"80px"} mb={"20px"} color={"brand.darkBrown"}>BUY THE COMIC</Heading>
          {/* <Text mt={"60px"} maxW={"601px"} color="brand.darkBrown">There are several ways you can purchase an issue of 'From Rahm To Dei':</Text>
            <UnorderedList color="brand.darkBrown" fontWeight={"normal"} spacing="3" my="20px">
                <ListItem>Buy in one of the <Linko to={"/buy#comicbookstores"}><Text as="span" fontWeight={"bold"} color="brand.orange" textDecoration={"underline"}>comic books stores</Text></Linko> on the list.</ListItem>
                <ListItem>Order directly <Linko to={"/buy#orderhere"}><Text as="span" fontWeight={"bold"} color="brand.orange" textDecoration={"underline"}>here</Text></Linko></ListItem>
                <ListItem>Buy a digital issue <Linko to={"/buy#buydigital"}><Text as="span" fontWeight={"bold"} color="brand.orange" textDecoration={"underline"}>on Gumroad</Text></Linko>.</ListItem>
            </UnorderedList> */}
        </Box>

        <Box id="comicbookstores" w={"full"} py={{base:"10px",lg:"50px"}} px="10vw" borderBottomColor={"brand.darkBrown"}>
            <Text textTransform={"uppercase"} fontWeight={"extrabold"} fontSize={"20px"} lineHeight={"30px"} mb="10px">Buy in store</Text>
            <Text>Following comic book sell 'From Rahm To Dei'. Contact them to check their stock!<br /><br />
                <Text as="span" fontWeight={"bold"}>Mekanik Strip</Text><br />
                Sint Jacobsmarkt 73<br />
                2000 Antwerpen<br />
                +32 (0)3 234 23 47<br />
                <Link to="mailto:info@mekanik-strip.be"><Text as="span" textDecoration={"underline"}>info@mekanik-strip.be</Text></Link><br />
                <Link to="http://www.mekanik-strip.be"><Text as="span" textDecoration={"underline"}>mekanik-strip.be</Text></Link><br />
                <br />
                <Text as="span" fontWeight={"bold"}>De Stripkever</Text><br />
                Bruul 792800, Mechelen<br />
                +32 15 21 76 05<br />
                <Link to="mailto:stripkever@skynet.be"><Text as="span" textDecoration={"underline"}>stripkever@skynet.be</Text></Link><br />
                <Link to="http://www.stripkever.be"><Text as="span" textDecoration={"underline"}>stripkever.be</Text></Link><br />
                <br />
                <Text as="span" fontWeight={"bold"}>Het Besloten Land</Text><br />
                Parijsstraat 16, Leuven<br />
                +32 (0)16 22 58 40<br />
                <Link to="mailto:info@hbl.be"><Text as="span" textDecoration={"underline"}>info@hbl.be</Text></Link><br />
                <Link to="https://www.hbl.be/"><Text as="span" textDecoration={"underline"}>hlb.be</Text></Link><br />              
              </Text>
                
        </Box>
        {/* <Box id={"orderhere"} w={"full"} py={{base:"10px",lg:"50px"}} px="10vw" borderBottom={"1px dashed"} borderBottomColor={"brand.darkBrown"}>
            <Text textTransform={"uppercase"} fontWeight={"extrabold"} fontSize={"20px"} lineHeight={"30px"} mb="10px">Order here</Text>
            <Text>You can order issues of 'From Rahm To Dei' here.<br />You can choose to pick it up or have it shipped to you via local postal service (Europe only).<br /><br />
                <Text as="span" fontWeight={"bold"}>Mekanik Strip</Text><br />
                Sint Jacobsmarkt 73<br />
                2000 Antwerpen<br />
                +32 (0)3 234 23 47<br />
               <br />
                <Text as="span" fontWeight={"bold"}>Pick up?</Text><br />
                You can pick up your issue (after payment) at the following addres:<br />
                Weerstandersstraat 91/1,<br/>
                2180 Ekeren<br />
                Belgium<br />
                </Text>
        </Box> */}

      </Flex>
        );
    }
    
export default Buy;
    